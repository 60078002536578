<template>
  <div class="wrap" style="height: 100vh;background-color: #fff;">   
    <van-form input-align="right" error-message-align="right">
      <van-field readonly label="合同状态" :value="list.contract_status" />
      <van-field autosize readonly label="合同号" :value="list.contract_number" />
      <van-field readonly label="车架号" :value="list.vin" />
      <van-field readonly label="姓名" :value="list.fullname" />
      <van-field readonly label="车型" :value="list.carkind" />
      <van-field readonly label="融资金额" :value="list.loan_amount | formatMoney(2)" />
      <van-field readonly label="还款银行" :value="list.bank" />
      <van-field readonly label="还款卡号" :value="list.cardno" />
      <van-field readonly label="手机号" :value="list.mobile" />
      <van-field readonly label="还款日" :value="list.planday" />
      <van-field readonly label="合同激活时间" :value="list.start_date" />
    </van-form>
    <van-col v-show="hasContract" span="20" offset="2" style="position:fixed;bottom:10px;">
      <van-button round block type="danger" @click="submit">
        查看电子合同
      </van-button>
    </van-col>
  </div>
</template>
<script>
  import {condetail,getOpenId,eleContractQuery,getImgToken,eleContractQueryZz,querytype} from '@/server'
  import { Toast } from 'vant';
  import { formatMoney } from '@/lib/format';
  import {setCookie,getCookie} from '@/lib/utils';
  export default {
  data() {
    return {
      cardId:this.$store.state.cardId,
      contractNo: this.$route.query.contractNo,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      period: this.$route.query.period,
      list:[],
      hasContract:false,
      addressUrl:''
    };
  },
  filters:{
    //格式化金额
    formatMoney(value, dot, digits){
      if(value == null){
        return '--'
      }else{
        return formatMoney(value, dot, digits)
      }
    },
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'contract3');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'contract3') {
    //     that.$router.push({ path: '/contractQuery' });
    //   }
    // }, false);
  },
  created(){
    this.getDetail();
  },
  methods: {
    //获取token
    getImgToken(){
      getImgToken({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          localStorage.setItem("token", res.img_token);
          if(this.list.con_type == 'huizu'){
            this.getHzContract()
          }else{
            this.getZzContract()
          }
        }
      })
    },
    getDetail(){
      const params = {
        contract_number:this.contractNo
      }
      condetail(params).then(res=>{
        if(res.status=='200'){
          this.list = res.data[0];
          // if(res.data[0].con_type=='huizu'){
          //   this.hasContract = true;
          // }else{
          //   this.hasContract = false;
          // }
          this.getImgToken();
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    getZzContract(){
      const params = {
        contractCode:this.contractNo
        // contractCode:'YJCC202010260001'
      }
      eleContractQueryZz(params).then(res=>{
        if(res.status=='200'){
          let {address} = res.data;
          this.addressUrl = address;
          if(address ==null || address ==''){
            this.hasContract = false;
          }else{
            this.hasContract = true;
          }
        }else{
          this.hasContract = false;
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    getHzContract(){
      const params = {
        contractCode:this.contractNo
        // contractCode:'OWN-43331-22000479'
      }
      eleContractQuery(params).then(res=>{
        if(res.status=='200'){
          let {address} = res.data;
          this.addressUrl = address;
          if(address ==null || address ==''){
            this.hasContract = false;
          }else{
            this.hasContract = true;
          }
        }else{
          this.hasContract = false;
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    submit(){
      const querytypeparams = {
        card_id:this.cardId,
        function_type:'button',
        button_name:'查看电子合同',
        pre_menu:sessionStorage.getItem('menu_name')
      }
      querytype(querytypeparams);
      // window.location.href="/oraflfile/share/privacy/x2rF46/698157637044547584"
      if(this.list.con_type == 'huizu'){
        this.$router.push({ path: '/pdfDisplay' ,query:{url:'oraflfile/hz'+this.addressUrl,contractNo:this.contractNo}});
      }else{
        this.$router.push({ path: '/pdfDisplay' ,query:{url:this.addressUrl,contractNo:this.contractNo}});
      }
     }
  },
};
</script>
<style>
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
.vanStep{
  margin: 15px;border-radius: 5px;
}
.van-step--finish{
  color: #E50012;
}
</style>